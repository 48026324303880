window.naomi ||= {}

window.naomi.formadd =

  setup: ->
    $('[data-behaviour="form-add"]').click (e) ->
      e.preventDefault()
      ele = $(this)
      sectionId = ele.data('section')
      sessionId = ele.data('session')
      $.ajax(
        url: '/submissions/add/'+ sessionId + '/' + sectionId
        method: 'GET'
        dataType: 'json'
        data: 'participantCount': ele.data('participant-count')
        success: (data) ->
          $(data.html).appendTo '.contain'
          ele.data 'participant-count', data.participantCount

          $('.remove-participant__container:last-child [data-behaviour="remove-participant"]').click (e) ->
            e.preventDefault()
            parent = $(this).parent('.remove-participant__container')
            parent.remove()
            $(this).off()
            return

          return
        error: ->
          alert 'Sorry, something went wrong. Please try again, or contact us if you continue to experience problems.'
          return
      ).always(data) ->
          if !$.trim(data.error)
          else
            $('.contain').append '<div class="participant--limit">' + data.error + '</div>'
            $('.form-add').addClass 'form-add--disabled'
          return
