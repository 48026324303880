window.naomi ||= {}
naomi.headertoggles =

  setup: ->
    @setup_main_nav()
    @setup_sub_nav()

  setup_main_nav: ->
    $('.menu_toggle').on 'click', ->
      $(this).toggleClass 'active'
      $('.sub_nav_trigger').removeClass 'active'
      $('#sub_nav_wrapper').slideUp('slow')
      if $(this).hasClass('active')
        $('#main_nav_wrapper').slideDown()
        $('#event_basket_popup').addClass 'main_menu_visible'
      else
        $('#main_nav_wrapper').slideUp 'normal', ->
          $('#event_basket_popup').removeClass 'main_menu_visible'


  setup_sub_nav: ->
    $('.sub_nav_trigger').click (e) ->
      e.preventDefault()
      $(this).toggleClass 'active'
      $('.menu_toggle').removeClass 'active'
      $('#main_nav_wrapper').slideUp('slow')
      $('#sub_nav_wrapper').slideToggle()

