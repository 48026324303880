window.naomi ||= {}
naomi.customforms =

  setup: ->
    @kill()
    @start_labels()
    @setupLabel()
    @setup_custom_dates()

  start_labels: ->
    $("body").on 'click', '.wrap_label', naomi.customforms.setupLabel

  kill: ->
    $(".wrap_label").off 'click', naomi.customforms.setupLabel

  setupLabel: ->
    if $(".wrap_label input").length > 0
      $(".wrap_label").each ->
        $(this).removeClass "c_on"
        $(this).removeClass "r_on"

      $(".checks .wrap_label input:checked").each ->
        $(this).closest("label").addClass "c_on"

      $(".radios .wrap_label input:checked").each ->
        $(this).closest("label").addClass "r_on"

  setup_custom_dates: ->
    $('.date_selector select').each ->
      $(this).wrap '<div class="custom_select"></div>'
      $(this).customSelect()
