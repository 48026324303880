window.naomi ||= {}
naomi.product_images =

  setup: ->
    @setup_swap_product_images()
    
  setup_swap_product_images: ->
    
    $product_image = $('.product_image_wrapper img')
    $variation = $('.variations input')

    #display first image
    $product_image.first().show()

    #remove active state display selected image variation
    $variation.on 'click', ->
      $product_image.hide()

      elementClass = $(this).attr('class')
      $('img.product_detail_image.' + elementClass).fadeIn()
      

          



