window.naomi ||= {}

window.naomi.basketquantity =

  setup: ->
    $('body').on 'change', '.quantity_number', (e) ->
      e.preventDefault()
      quantity = $(this)
      $.ajax(
        url: 'basket/edit'
        method: 'POST'
        data: 'variation_id': quantity.data('variation-id'), 'quantity': quantity.val()
        dataType: 'json'
        success: (data) ->
          $('#basket_table_wrapper').html data.main_contents
          if !$.trim(data.error)
          else
            $('#basket_table').prepend '<div class="stock-limit">' + data.error + '</div>'
          return
        error: () ->
          alert 'Sorry, we were unable to update your basket. Please try again, or contact us if you continue to experience problems.'
          return 
      )
      return
