window.naomi ||= {}

window.naomi.basket =

  setup: ->
    @link = $('a#basket_link')
    @error_panel = $('.errors')

    @product_price_set()
    @update_product_price()

    @setup_add_to_basket_forms()
    @setup_basket()

  update_product_price: ->
    $('.basket_add_form .variations input').on 'click', ->
      window.naomi.basket.product_price_set()

  product_price_set: ->
    $variation = $('.basket_add_form .variations input:checked')
    if $variation
      $('.sell_price').text($variation.data('price'))

  setup_add_to_basket_forms: ->
    $('.basket_add_form').on 'submit', (e) ->
      e.preventDefault()
      $('.errors').hide()

      $form = $(this)
      $button = $form.find('button')
      $button.data('text', $button.text()).text('Please wait..')

      $.ajax
        url: $form.prop('action')
        type: 'post'
        data: $form.serialize()
        dataType: 'json'
        error: ->
          if $('input[data-options="2"]').length > 0 && $('input[data-options="2"]:checked').length == 0
            $('[data-behaviour="form-error"]').text "Please choose an option"
          else
            $('[data-behaviour="form-error"]').text 'Sorry, we were unable to add this item to your basket. Please try again, or contact us if you continue to experience problems.'
      .done (data, status, xhr) ->
        $('[data-behaviour="form-error"]').text ""
        if data.error != null
          $('[data-behaviour="form-error"]').text data.error
        else
          naomi.basket.update_elements_with data
          naomi.basket.setup_basket()
          $('[data-element="basket-button"]').show()
      .always ->
          $button.text $button.data('text')

  update_elements_with: (data) ->
    $('span', @link).text data.count # Update header link

    # Update errors
    if data.errors && @error_panel.length > 0
      @error_panel.text data.errors
      @error_panel.show()

    # Update main basket page
    if $('#basket_table_wrapper').length > 0
      $('#basket_table_wrapper').html(data.main_contents)

    #update basket popup if were not on the main basket page
    else if $('#basket_popup').length > 0 && !data.errors
      $('#basket_popup').html(data.popup_content)
      @setup_basket_popup()

  setup_basket_popup: ->
    #show the popup
    $('#basket_popup').addClass('open')

    #register events for
    $('#close_basket_link').on 'click', (e) ->
      e.preventDefault()
      $('#basket_popup').removeClass('open').html('')

    $('body').on 'click', ':not(#basket_popup)', (e) ->
      $('#basket_popup').removeClass('open').html('')

    #scroll to top so we see it
    $('html, body').animate
      scrollTop: 0
    , 'normal'

  setup_basket: ->
    @table = $('#basket_table')
    @remove_update_buttons()

  remove_update_buttons: ->
    $('#basket_table .quantity_submit').hide()
