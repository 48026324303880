window.naomi ||= {}

window.naomi.event_basket =

  setup: ->
    @error_panel = $('.errors')

    @setup_add_to_basket_forms()
    @setup_hide_button()

  setup_add_to_basket_forms: ->
    $('.event_basket_add_form').on 'submit', (e) ->
      e.preventDefault()
      $('.errors').hide()

      $form = $(this)
      $button = $form.find('button')
      $button.data('text', $button.text()).text('Please wait..')

      $.ajax
        url: $form.prop('action')
        type: 'post'
        data: $form.serialize()
        dataType: 'json'
        error: ->
          alert 'Sorry, we were unable to add this item to your basket. Please try again, or contact us if you continue to experience problems.'
      .done (data, status, xhr) ->
        naomi.event_basket.update_elements_with data
      .always ->
          $button.text $button.data('text')



  update_elements_with: (data) ->
    # Update errors
    if data.errors && @error_panel.length > 0
      @error_panel.text data.errors
      @error_panel.show()

    #update basket popup if were not on the main basket page
    else if $('#event_basket_popup').length > 0 && !data.errors
      $('#event_basket_popup').html(data.popup_content)
      $('#event_basket_popup').find('.event_order_summary').prepend('<h2 class="heading">Photo added to your collection</h2>')
      @setup_basket_popup()

  setup_basket_popup: ->
    #show the popup
    if $('#event_basket_popup').hasClass('open')
      $('#event_basket_wrapper').show()
      $('#show_basket_wrapper').hide()
    else
      $('#event_basket_wrapper').slideDown()
      $('#show_basket_wrapper').slideUp()
      $('#event_basket_popup').addClass('open')

    @setup_hide_button()

    #scroll to top so we see it
    $('html, body').animate
      scrollTop: 0
    , 'normal'


  setup_hide_button: ->
    $('#hide_basket_link').on 'click', (e) ->
      e.preventDefault()
      $('#event_basket_wrapper').slideUp()
      $('#show_basket_wrapper').slideDown()
      $('#event_basket_popup').removeClass('open')


    $('#show_basket_link').on 'click', (e) ->
      e.preventDefault()
      $('#event_basket_wrapper').slideDown()
      $('#show_basket_wrapper').slideUp()
