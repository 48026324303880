window.naomi ||= {}
naomi.skrollr =

  setup: ->
    # Init Skrollr


    # Setup variables
    $window = $(window)
    $slide = $('.link_block_wrapper')
    $body = $('body')


    s = skrollr.init(

      forceHeight: false
      render: (data) ->

        #Debugging - Log the current scroll position.
        # console.log(data.curTop)

    )

    # disable skrollr if using handheld device
    skrollr.init().destroy()  if /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)


