window.naomi ||= {}

window.naomi.login =

  setup: ->
    # Show/Hide second password field depening on if the user is new or existing
    $('[data-behaviour="pass-field-show"]').on 'click', ->
      $('[data-element="secondary-password"]').show()
      return
    $('[data-behaviour="pass-field-hide"]').on 'click', ->
      $('[data-element="secondary-password"]').hide()
      return
