window.naomi ||= {}

window.naomi.checkout =

  setup: ->
    @setup_us_states()
    @setup_countries()
    @setup_billing_address()

  setup_us_states: ->
    $("#order_delivery_country, #order_billing_country").each ->
      $country = $(this).val()
      $state_field = $("#" + $(this).attr("id").replace("country", "state_field"))
      if $country == "United States of America"
        $state_field.show()
      else
        $state_field.hide()

  setup_countries: ->
    $('#order_delivery_country, #order_billing_country').on "change", (e) ->
      naomi.checkout.setup_us_states()

  setup_billing_address: ->

    copy_button = $("#use_delivery_for_billing")
    billing_fields  = $("[id^=order_billing]")
    delivery_fields = $("[id^=order_delivery]")

    enable_copy_from_delivery = ->
      delivery_fields.on "change keyup", ->
        corresponding_billing_field = $( "[id=#{ $(this).attr("id") }]".replace("delivery", "billing") )
        corresponding_billing_field.val $(this).val()
        corresponding_billing_field.trigger "change"
      delivery_fields.trigger "change"

    disable_copy_from_delivery = ->
      delivery_fields.off "change keyup"

    set_delivery_copy_status = ->
      if copy_button.is(":checked")
        enable_copy_from_delivery()
      else
        disable_copy_from_delivery()

    set_delivery_copy_status()

    copy_button.on "change", (e) ->
      e.preventDefault()
      set_delivery_copy_status()
