require('./tictoc/pagination.js.coffee')
require('./tictoc/preview.js.coffee')

require('./naomi/analytics.js.coffee')
require('./naomi/basket.js.coffee')
require('./naomi/basketremove.js.coffee')
require('./naomi/checkout.js.coffee')
require('./naomi/custom_select.js.coffee')
require('./naomi/customforms.js.coffee')
require('./naomi/event_basket.js.coffee')
require('./naomi/event.js.coffee')
require('./naomi/formadd.js.coffee')
require('./naomi/gallery.js.coffee')
require('./naomi/headertoggles.js.coffee')
require('./naomi/maps.js.coffee')
require('./naomi/product_image.js.coffee')
require('./naomi/skrollr.js.coffee')
require('./naomi/video.js.coffee')
require('./naomi/login.js.coffee')
require('./naomi/submission.js.coffee')
require('./naomi/basket_quantity.js.coffee')
require('./naomi/basket_donation.js.coffee')
require('./naomi/toggle_options.js.coffee')


$ ->
  $("body").addClass "has-js"
  $.ajaxSetup({
    headers: {
      "csrf-token": $("meta[name='csrf']")[0].content
    }
  })
  tictoc.preview.setup()
  tictoc.pagination.setup()
  naomi.checkout.setup()
  naomi.basket.setup()
  naomi.event_basket.setup()
  naomi.event.setup()
  naomi.headertoggles.setup()
  naomi.custom_select.setup()
  naomi.customforms.setup()
  naomi.skrollr.setup()
  naomi.gallery.setup()
  naomi.maps.setup()
  naomi.product_images.setup()
  naomi.video.setup()
  naomi.analytics.setup()
  naomi.formadd.setup()
  naomi.basket_remove.setup()
  naomi.submission.setup()
  naomi.login.setup()
  naomi.basketquantity.setup()
  naomi.basketdonation.setup()
  naomi.toggleoptions.setup()
  naomi.event.setup()
