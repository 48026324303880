window.naomi ||= {}

window.naomi.basketdonation =

  setup: ->
    $('body').on 'change', '#basket_table_wrapper #enter_amount', (e) ->
      e.preventDefault()
      donation = $(this)
      $.ajax(
        url: 'basket/donation'
        method: 'POST'
        data: 'amount': donation.val()
        dataType: 'json'
        success: (data) ->
          $('#basket_table_wrapper').html data.main_contents
          return
        error: () ->
          alert 'Sorry, we were unable to update your basket. Please try again, or contact us if you continue to experience problems.'
          return
      )
      return
