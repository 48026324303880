window.naomi ||= {}

naomi.video =
  setup : ->
    @setup_embedded_videos()
    @setup_mediaelement_player()

  setup_embedded_videos: ->
    $video_links = $('.ugc')
    return false if $video_links.length == 0
    attrs =
      frameborder: 0
      webkitallowfullscreen: ""
      mozalllowfillscreen: ""
      allowfullscreen: ""
      type: "text/html"

    $iframe = $('<iframe />')
    $iframe.attr( attrs )

    # YouTube
    $('.ugc a[href*="youtube.com"]').each ->
      if @.href.match /v=/
        $(@).replaceWith $iframe.clone().addClass("youtube-player").attr
          src: "//www.youtube.com/embed/#{@href.match(/v=([\w\-]+)/)[1]}?rel=0&amp;controls=1&amp;showinfo=0&amp;playsinline=1"
          width: $(@).parent().width()
          height: $(@).parent().width()/16 * 9

    # YouTube shortlink
    $('.ugc a[href*="youtu.be"]').each ->
      $(@).replaceWith $iframe.clone().addClass("youtube-player").attr
        src: "//www.youtube.com/embed/#{@href.match(/\.be\/([\w\-]*)/)[1]}?rel=0"
        width: $(@).parent().width()
        height: $(@).parent().width()/16 * 9

    # Vimeo
    $('.ugc a[href*="vimeo.com"]').each ->
      return false if @href.match /user/
      if @href.match /\d+$/
        clip_id = this.href.match(/(\d+$)/)[1];
        $(@).replaceWith $iframe.clone().addClass("vimeo-player").attr
          src: "//player.vimeo.com/video/#{clip_id}?title=0&amp;portrait=0&amp;byline=0&amp;badge=0"
          width: $(@).parent().width()
          height: $(@).parent().width()/16 * 9

  setup_mediaelement_player: ->
    $('.ugc a[href*="mp4"]').mediaelementplayer
      videoWidth: '100%'
      videoHeight: '100%'
      enableAutosize: true
      pluginPath: '/app_assets/'
      flashName: "<%= asset_path('flashmediaelement.swf').split('/').last %>"
      silverlightName: "<%= asset_path('silverlightmediaelement.xap').split('/').last %>"


