window.naomi ||= {}

window.naomi.analytics =
  setup : ->
    $('.footer_social_item').on 'click', (e) ->
      if $(this).hasClass('youtube')
        window.naomi.analytics.track_event("Social Media", 'Click', 'youtube')

      if $(this).hasClass('instagram')
        window.naomi.analytics.track_event("Social Media", 'Click', 'instagram')

      if $(this).hasClass('linkedin')
        window.naomi.analytics.track_event("Social Media", 'Click', 'linkedin')

      if $(this).hasClass('facebook')
        window.naomi.analytics.track_event("Social Media", 'Click', 'facebook')

      if $(this).hasClass('twitter')
        window.naomi.analytics.track_event("Social Media", 'Click', 'twitter')


    $('#job_form button[type="submit"]').on 'click', (e) ->
      window.naomi.analytics.track_event('Job Application', 'Click', document.title)

    $('.basket_add_form button').on 'click', (e) ->
      window.naomi.analytics.track_event('Add to basket', 'Click', document.title)

    $('.fundraise .ugc a[href$=".pdf"]').on 'click', (e) ->
      window.naomi.analytics.track_event('Resource Name', 'Click', $(this).attr('title'))

    $('a[title="Download our lottery form"]').on 'click', (e) ->
      window.naomi.analytics.track_event('Lottery Form', 'Click', $(this).attr('title'))

    $('.contact_blocks_item a').on 'click', (e) ->
      window.naomi.analytics.track_event('Contact links', 'Click', $(this).attr('href'))

    

  track_event: (category, action, label, value) ->
    return if typeof (ga) is "undefined" or not ga?
    
    ga('send', 'event', category, action, label, value)