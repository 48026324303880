window.tictoc ||= {}

window.tictoc.pagination =

  container: null
  pagination: null
  load_button: null
  loading: false

  setup: ->
    @pagination = $('.continuous_pagination')
    @container = $('[data-element="listing-container"]')

    return false if @pagination.length == 0 || @container.length == 0

    @load_button = $("<div class='load_more_wrapper u-flex u-justify-center'><a class='load_more button button--wide'>Load More</a></div>")
    @container.after( @load_button)

    @pagination.hide()
    @setup_loader()

    $('.continuous_pagination').attr 'data-page', '0'

  setup_loader: ->
    @load_button.on 'click', (e) =>
      e.preventDefault()
      if !@loading
        @increment_page_field()
        @load_items()
  load_items: ->
    @loading = true
    next_page = parseInt(@pagination.data('page'))
    $.ajax
      url: document.URL + (if document.URL.match(/\?/) then  "&" else "?") + "ccm_paging_p=" + next_page + "&ccm_order_by=&ccm_order_by_direction="
      data:
        page: next_page
      dataType: 'html'
    .done (data, status, xhr)=>
      $result = $(data).find('[data-element="listing-item"]')
      @container.append($result)
    .always =>
      @loading = false
      @check_for_more_pages()
      headings = document.getElementsByTagName('h1')
      i = 0
      while i < headings.length
        headings[i].style.margin = '1px'
        i++

  check_for_more_pages: ->
    total_pages = parseInt(@pagination.data('total-pages'))
    next_page = parseInt(@pagination.data('page'))

    if total_pages == next_page
      @load_button.hide()
      @loading = true

  increment_page_field: ->
    current_page = @pagination.data('page')
    if current_page
      next_page = parseInt(current_page, 10) + 1
    else
      next_page = 2
    @pagination.data('page', next_page)
