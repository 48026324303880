window.naomi ||= {}

window.naomi.maps =

  api_key : "AIzaSyCKbmb9af5kKU2hVAIQ_Ld1PRSHkZ2edTA"

  setup : ->
    return false if $("#google_map").length == 0

    # Load the google map api
    do (d= document, s= "script", id ="gmap_loader") =>
      fjs = d.getElementsByTagName(s)[0]

      if !d.getElementById(id)
        # If api hasnt already been loaded
        js = d.createElement s
        js.id = id
        js.src = "https://maps.googleapis.com/maps/api/js?key=#{@api_key}&callback=window.naomi.maps.initialize"
        fjs.parentNode.insertBefore js, fjs
      else
        # If api has already been loaded
        @initialize()


  initialize : ->
    @setup_locations()

    @options =
      center        : @bounds.getCenter()
      zoom          : if $("#google_map").attr("data-zoom") then parseInt($("#google_map").attr("data-zoom")) else 11
      mapTypeId     : google.maps.MapTypeId.ROADMAP
      mapTypeControl: false
      scrollwheel   : false

    @map = new google.maps.Map(document.getElementById("google_map"), @options)

    @setup_info_window()
    if @locations.length > 1
      @map.fitBounds @bounds
    else
      @map.setCenter({lat: 54.5, lng: -3.5})

    if @locations.length == 1
      if $('.map-block__map').attr("data-lat") && $('.map-block__map').attr("data-lng")
        @map.setCenter( {lat: $('.map-block__map').attr("data-lat")*1, lng: $('.map-block__map').attr("data-lng")*1})
        @map.setZoom(if $("#google_map").attr("data-zoom") then parseInt($("#google_map").attr("data-zoom")) else 11)
      else
        @map.setCenter({lat: 54.5, lng: -3.5})
        @map.setZoom(6)

    @add_markers()


  setup_locations : ->

    @locations = []
    @bounds = new google.maps.LatLngBounds()

    $(".article_listing .article_stub_item, .map-block__map").each (_,element) =>
      $map_location = $(element)
      return true if !$map_location.attr("data-lat") || !$map_location.attr("data-lng")
      location =
        lat      : $map_location.attr("data-lat")
        lng      : $map_location.attr("data-lng")
        position : new google.maps.LatLng( $map_location.attr("data-lat"), $map_location.attr("data-lng") )
        content  : $map_location.html()

      @locations.push location
      @bounds.extend location.position


  setup_info_window : ->
    @info_window = new google.maps.InfoWindow()


  add_markers : ->
    $.each @locations, (_, element) =>

      marker = new google.maps.Marker
        position : element.position
        map      : @map
        flat     : true
        icon     : "/application/themes/rawnet/app/images/icons/map_marker.png"

      google.maps.event.addListener marker, 'click', =>
        @info_window.setContent element.content
        @info_window.open @map, marker
