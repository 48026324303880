window.naomi ||= {}

window.naomi.basket_remove =

  setup: ->
    $('body').on 'click', '.remove_button', (e) ->
      e.preventDefault()
      $.ajax(
        url: 'basket/remove'
        method: 'POST'
        data: 'variation_id': $(this).data('variation-id')
        dataType: 'json'
        success: (data) ->
          $('#basket_table_wrapper').html data.main_contents
          $('[data-element="basket-count"]').text data.count
          return
        error: ->
          alert 'Sorry, we were unable to update your basket. Please try again, or contact us if you continue to experience problems.'
          return
      ).always ->
          $('.checkout .btn_icon').html 'Please Wait..'
          return 
      return
