window.naomi ||= {}
naomi.gallery =
  
  galleries: []

  setup: ->
    $('#gallery_wrapper').each ->
      gallery = 
        active_slide : 0
        slides : $(@).find('.slide')
        carousel : $(@).find('.carousel')
        original_count : $(@).find('.slide').length
        tabs : null
        prev : $(@).find('.carousel_control_previous')
        next : $(@).find('.carousel_control_next')
        nav : $(@).find('#gallery_nav')
      window.naomi.gallery.galleries.push(gallery)

      window.naomi.gallery.clone_slides(gallery)
      window.naomi.gallery.initial_state(gallery)
      gallery.slides = $(@).find('.slide')

      window.naomi.gallery.attach_event_listeners(gallery)
      window.naomi.gallery.gallery_nav(gallery)

  clone_slides: (gallery)->
    # create clones
    $end_clones = gallery.slides.clone().addClass 'clone'
    $start_clones = gallery.slides.clone().addClass 'clone'

    # add clones to start and end
    gallery.carousel.append $end_clones
    gallery.carousel.prepend $start_clones
    gallery.active_slide = gallery.original_count

    gallery.slides.eq(gallery.active_slide).addClass 'active'
    gallery.carousel.css 'left', "-#{gallery.original_count}00%"

  initial_state: (gallery)->
    gallery.slides.eq(0).addClass 'active'

  attach_event_listeners: (gallery) ->
    gallery.next.on 'click', =>
      gallery.active_slide += 1
      if gallery.slides.eq(gallery.active_slide).hasClass 'clone'
        gallery.carousel.stop(false, false).css 'left', "-#{gallery.original_count-1}00%"
        gallery.active_slide = gallery.original_count

      @animate_carousel(gallery, gallery.active_slide)
      @update_tabs(gallery, gallery.active_slide)

    gallery.prev.on 'click', =>
      gallery.active_slide -= 1
      if gallery.slides.eq(gallery.active_slide).hasClass 'clone'
        gallery.carousel.stop(false, false).css 'left', "-#{gallery.slides.length - gallery.original_count}00%"
        gallery.active_slide = gallery.slides.length - gallery.original_count - 1

      @animate_carousel(gallery, gallery.active_slide)
      @update_tabs(gallery, gallery.active_slide)

  remove_event_listeners: (gallery) ->
    gallery.prev.off 'click'
    gallery.next.off 'click'

  update_tabs: (gallery, active_idx) ->
    gallery.tabs.removeClass 'active_tab'
    gallery.tabs.eq(active_idx).addClass 'active_tab'

  animate_carousel: (gallery, active_idx) ->
    gallery.slides.removeClass 'active'
    @remove_event_listeners(gallery)

    gallery.carousel.animate({
      left: "-#{active_idx}00%"
    }, 'normal', =>
      gallery.slides.eq(active_idx).addClass 'active'
      @attach_event_listeners(gallery)
    )

  gallery_nav: (gallery) ->
    gallery.slides.each (idx)->
      $toggle = $("<a class='gallery_toggle'></a>")
      $toggle.addClass('clone') if $(@).hasClass('clone')
      gallery.nav.append $toggle

    gallery.tabs = gallery.nav.find('.gallery_toggle')
    gallery.nav.find(".gallery_toggle:nth-child(#{gallery.active_slide + 1})").addClass('active_tab')

    gallery.nav.on 'click', '.gallery_toggle', ->
      gallery.active_slide = gallery.tabs.index(@)
      naomi.gallery.animate_carousel(gallery,gallery.active_slide)

      naomi.gallery.update_tabs(gallery,gallery.active_slide)

