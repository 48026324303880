window.naomi ||= {}

window.naomi.toggleoptions =

  setup: ->
    $('[data-behaviour="trigger-option"] input').prop('checked', false);
    $('[data-behaviour="target-option"]').hide()
    $('[data-always="target-always"]').hide()
    $('[data-behaviour="trigger-option"] input').on 'click', ->
      option = $(this).val()
      if option == 'SMS' && $('[data-behaviour="trigger-option"][value="Phone"]:checked').length
        return
      if option == 'Phone' && $('[data-behaviour="trigger-option"][value="SMS"]:checked').length
        return
      if option == 'SMS'
        option = 'Phone'
      $('[data-behaviour="target-option"][data-target="' + option + '"]').toggle()
      $('[data-always="target-always"]:hidden').toggle()
      checkboxes = $('[data-behaviour="trigger-option"] input:checked').length
      if checkboxes == 0
        $('[data-always="target-always"]').toggle()
      return
