window.naomi ||= {}

naomi.custom_select =
  timer: null
  
  setup: ->
    $('.custom_select select, .user_form select').customSelect().trigger('render')
    @attach_listeners()
  
  attach_listeners: ->
    breakpoints =
      mobile_landscape: window.matchMedia 'screen and (min-width: 480px)'
      small_tablet: window.matchMedia 'screen and (min-width: 600px)'
      tablet: window.matchMedia 'screen and (min-width: 768px)'
      desktop: window.matchMedia 'screen and (min-width: 960px)'
  
    _.each breakpoints, (mq)->
        mq.addListener @retrigger_selects
      , @
  
  
  retrigger_selects: ->
    window.clearTimeout(naomi.custom_select.timer)
    naomi.custom_select.timer = window.setTimeout ->
        $('.custom_select select, .user_form select').trigger('render')
      , "100"
